<template>
  <v-container style="max-width: 980px; margin: 0 auto;">


    <div class="d-flex justify-space-between mb-4">
      <SelectorUI v-model="actual" :items="[
                                         {value: 0, name: 'Прошедшие'},
                                         {value: 1, name: 'Будущие'},

                                      ]" :default-value="1"/>
      <div>
        <ButtonUI
            :type="$vuetify.breakpoint.smAndDown ? 'float' : 'default'"
            @click.native="$router.push('/events/create')"
        >
          Создать мероприятие
        </ButtonUI>
      </div>

    </div>


    <v-row v-if="events.length > 0">
      <v-col v-for="event in events" :key="event.id" cols="12" lg="4">
        <EventCardUI :event="event"/>

        <!--                <v-card outlined-->
        <!--                        class="mx-auto"-->
        <!--                >-->
        <!--                    <v-img-->
        <!--                            :src="event.header_url"-->
        <!--                            height="200px"-->
        <!--                    ></v-img>-->

        <!--                    <v-card-title class="title">{{event.name}}</v-card-title>-->

        <!--                    <v-card-subtitle>-->
        <!--                        {{event.start_at}}-->
        <!--                    </v-card-subtitle>-->
        <!--                    <v-card-text>-->
        <!--                        <div style="height: 150px; display: inline-block; overflow:hidden;  text-overflow: ellipsis;">-->
        <!--                            {{event.description}}-->
        <!--                        </div>-->

        <!--                    </v-card-text>-->
        <!--                    <v-card-actions>-->

        <!--                        <v-btn :to="{name: 'events.item.info', params: {id: event.id}}"-->
        <!--                               color="primary"-->
        <!--                               text-->
        <!--                        >-->
        <!--                            Подробнее-->
        <!--                        </v-btn>-->

        <!--                        <v-spacer></v-spacer>-->
        <!--                    </v-card-actions>-->

        <!--                </v-card>-->
      </v-col>
    </v-row>
    <div v-else>
      <v-alert border="left"
               class="mt-2"
               outlined
               type="warning"
      >
        Ближайших мероприятий нет, но вы можете организовать новое
      </v-alert>
    </div>

  </v-container>
</template>

<script>
// import InputDatePicker from "@/components/Utility/InputDatePicker";

import ButtonUI from "@/components/UI/ButtonUI.vue";
import SelectorUI from "@/components/UI/SelectorUI.vue";
import EventCardUI from "@/components/Events/EventCardUI.vue";

export default {
  name: "EventsComponent",
  components: {
    SelectorUI,
    ButtonUI,
    EventCardUI
    // InputDatePicker
  },
  mounted() {
    this.$store.dispatch('getEvents');
    this.$store.dispatch('getUserEvents');
    this.$store.dispatch('getEmployees');
    this.$emit('changeTitle', 'Мероприятия')
  },
  data() {
    return {
      actual: 1
    }
  },
  computed: {
    events() {
      if (this.my) return [...this.$store.state.events.events.filter((el) => {
        return this.actual === 0 && this.$moment.utc(el.end_at).isBefore(this.$moment.utc()) || this.actual == 1 && this.$moment.utc(el.end_at).isAfter(this.$moment.utc())
      })].reverse()
      else return this.$store.state.events.events.filter((el) => {
        return this.actual === 0 && this.$moment.utc(el.end_at).isBefore(this.$moment.utc()) || this.actual == 1 && this.$moment.utc(el.end_at).isAfter(this.$moment.utc())
      })
    }
  },
  props: {
    my: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>
